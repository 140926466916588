var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-simple-table", {
    staticClass: "table-tpl",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "table",
              _vm._l(_vm.rows, function(row, rIdx) {
                return _c(
                  "tr",
                  { key: rIdx },
                  _vm._l(row.cells, function(cell, cIdx) {
                    return _c("td", { key: cIdx }, [
                      _vm._v(" " + _vm._s(cell.value || "") + " ")
                    ])
                  }),
                  0
                )
              }),
              0
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }