var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return typeof _vm.rendered === "string"
    ? _c("div", { domProps: { innerHTML: _vm._s(_vm.rendered) } })
    : _vm.rendered.length
    ? _c(
        "div",
        _vm._l(_vm.rendered, function(item) {
          return _c("div", { key: item.key }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.getValue(item["items-map"])) }
            })
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }