var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rendered.length
    ? _c(
        "div",
        _vm._l(_vm.rendered, function(item) {
          return _c("div", {
            key: item.key,
            domProps: { innerHTML: _vm._s(item.label) }
          })
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }