/**
 * metaData samples for template in comments
 */

// "Prodotto e confezionato"
import DefaultTpl from "./DefaultTpl";
// "[{\"key\":\"woolmark\",\"label\":\"Woolmark\"}]"
import LabelsTpl from "./LabelsTpl";
// "[{\"items-map\":{\"businessName\":{\"type\":\"STRING\",\"code\":\"businessName\", ...}]"
import ItemsMapTpl from "./ItemsMapTpl";
// "{\"type\":\"TABLE_TEXTUAL\",\"code\":\"dissolvedSubstances\",\"name\":\"Sostanze disciolte in un litro d'acqua in mg/l\", ..."
import TableTpl from "./TableTpl";
import NameAndValueTpl from "./NameAndValueTpl";
// "{\"type\":\"TEXT\",\"code\":\"colour\",\"name\":\"Colore\",\"tip\":null,\"value\":\"Bianco\",\"textualValue\":null}"
import ValueTpl from "./ValueTpl";
// "[{\"items-map\":{\"productIdentifier\":{\"type\":\"TEXT\",\"code\":\"productIdentifier\", ... ,\"warnings\":{\"type\":\"TEXT\",\"code\":\"warnings\",\"name\":\"Avvertenze\",\"tip\":null,\"value\":\"test\",... }]"
import WarningsTpl from "./WarningsTpl";
// {"type":"LIST","code":"packagedInProtectiveAtmosphere","name":"Confezionato in atmosfera protettiva","tip":null,"options":[]}
import ListTpl from "./ListTpl";

export {
  DefaultTpl,
  LabelsTpl,
  ItemsMapTpl,
  TableTpl,
  NameAndValueTpl,
  ValueTpl,
  WarningsTpl,
  ListTpl
};
