var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Breadcrumb", { attrs: { items: _vm.breadcrumb } }),
      _c(
        "v-container",
        [
          _vm.product.productId
            ? _c("ProductDetail", {
                key: "product_detail_" + _vm.key,
                attrs: {
                  product: _vm.product,
                  selectedImage: _vm.selectedImage
                },
                on: {
                  selectImage: _vm.selectImage,
                  goToCustomerVote: _vm.goToCustomerVote
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-container",
        [
          _vm.product.productId
            ? _c("ProductDescription", {
                key:
                  "product_description_" +
                  _vm.product.productId +
                  "_key_" +
                  _vm.key,
                attrs: {
                  product: _vm.product,
                  productMetadata: _vm.product.metaData,
                  ingredientList: _vm.product.ingredientList
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grey lighten-1" },
        [
          _c(
            "v-container",
            [
              _c(
                "ProductListSlider",
                {
                  key: _vm.product.productId + "_3",
                  attrs: {
                    productId: _vm.product.productId,
                    layout: 220,
                    paginationClass: "swiper-pagination-linea-220",
                    showArrows: !_vm.$vuetify.breakpoint.xsOnly,
                    showBullets: _vm.$vuetify.breakpoint.xsOnly,
                    position: "product_variations"
                  }
                },
                [
                  _c("h2", { staticClass: "secondary--text my-3" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("product.oftenBoughtTogether")) + " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "ProductListSlider",
            {
              key: _vm.product.productId + "_4",
              attrs: {
                productId: _vm.product.productId,
                layout: 223,
                paginationClass: "swiper-pagination-linea-223",
                showArrows: !_vm.$vuetify.breakpoint.xsOnly,
                showBullets: _vm.$vuetify.breakpoint.xsOnly,
                position: "product_variations"
              }
            },
            [
              _c("h2", { staticClass: "secondary--text mb-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("product.recommendedForYou")) + " ")
              ])
            ]
          )
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: {
          target: _vm.category,
          productId: _vm.product.productId,
          position: "product1"
        }
      }),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: {
          target: _vm.category,
          productId: _vm.product.productId,
          position: "product2"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }