var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rendered
    ? _c("div", [
        _vm._v(_vm._s(`${_vm.rendered.name}: ${_vm.rendered.value || ""}`))
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }