var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c(
        "v-row",
        { staticClass: "product-detail" },
        [
          _c(
            "v-col",
            { staticClass: "py-0 py-sm-3", attrs: { cols: "12", sm: "6" } },
            [
              _vm.selectedImage
                ? _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c("ProductImages", {
                        key: _vm.product.productId,
                        attrs: {
                          images: _vm.product.media,
                          name: _vm.product.name
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 py-sm-3 default--text",
              staticStyle: { position: "relative" },
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _c("h1", { staticClass: "secondary--text" }, [
                    _vm._v(" " + _vm._s(_vm.product.name) + " ")
                  ]),
                  _c(
                    "span",
                    [
                      _c(
                        "v-tooltip",
                        {
                          key: _vm.heartKey,
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              icon: "",
                                              large: "",
                                              "aria-label":
                                                "Aggiungi ad una lista"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.addToFavorites.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              mousedown: function($event) {
                                                $event.stopPropagation()
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "transition",
                                          { attrs: { name: "pulse" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                key: _vm.highlight,
                                                attrs: { color: "primary" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.highlight
                                                        ? "$heartfull"
                                                        : "$heart"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1241745904
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("product.addToList")))
                          ])
                        ]
                      ),
                      _vm.recipeList && _vm.recipeList.length > 0
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  link: "",
                                                  to:
                                                    _vm.recipeList[0]
                                                      .recipe_url,
                                                  "aria-label":
                                                    "Vai alle ricette con questo prodotto"
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey darken-1"
                                                }
                                              },
                                              [_vm._v("$recipe")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1267339012
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("product.goToRecipe")))
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _c("h4", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.product.description) +
                    " " +
                    _vm._s(_vm.product.shortDescr) +
                    " "
                ),
                _vm.product.productInfos &&
                _vm.product.productInfos.TIPOLOGIA == "Pezzo" &&
                _vm.product.priceUmDisplay
                  ? _c("span", [
                      _vm._v(
                        " al " +
                          _vm._s(_vm.product.weightUnitDisplay) +
                          " " +
                          _vm._s(
                            _vm.$n(_vm.product.priceUmDisplay, "currency")
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.product.productInfos.TIPOLOGIA == "Pezzo" &&
              _vm.product.productInfos.PACKAGE_DESCR
                ? _c("span", { staticClass: "text-caption" }, [
                    _vm._v(
                      " " + _vm._s(_vm.product.productInfos.PACKAGE_DESCR) + " "
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "code" },
                [
                  _c("v-chip", [
                    _vm._v(" Cod.Art. "),
                    _c("strong", [_vm._v(" " + _vm._s(_vm.product.codInt))])
                  ])
                ],
                1
              ),
              _vm.productClasses && _vm.productClasses.length > 0
                ? _c(
                    "div",
                    { staticClass: "mb-4 mb-sm-0 mb-md-3 characteristics" },
                    [
                      _c("div", { staticClass: "mb-3 font-weight-bold" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("product.characteristics")) + " "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-wrap product-class-wraper"
                        },
                        _vm._l(_vm.productClasses, function(pClass) {
                          return _c("ProductClass", {
                            key: pClass.productClassId,
                            attrs: { pClass: pClass, width: 34, height: 34 }
                          })
                        }),
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "promo-container my-2" },
                [
                  _vm.product.productInfos.BADGE
                    ? _c("div", { staticClass: "product-badge" }, [
                        _vm._v(
                          " " + _vm._s(_vm.product.productInfos.BADGE) + " "
                        )
                      ])
                    : _vm._e(),
                  _vm.product.warehousePromo
                    ? _c("ProductPromo", {
                        attrs: { warehousePromo: _vm.product.warehousePromo }
                      })
                    : _vm.product.newProduct == true
                    ? _c("span", { staticClass: "promo-box new-product" }, [
                        _vm._v(" NOVITA' ")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.selectOptionsArray, function(selectOption) {
                return _c(
                  "div",
                  {
                    key: selectOption.name,
                    staticClass: "option-wrapper mt-3",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                      mousedown: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        items: selectOption.options,
                        label: selectOption.name,
                        "item-text": "key",
                        "item-value": "value"
                      },
                      on: { change: _vm.optionChanged },
                      model: {
                        value: _vm.selectedOptions[selectOption.parameterName],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedOptions,
                            selectOption.parameterName,
                            $$v
                          )
                        },
                        expression:
                          "selectedOptions[selectOption.parameterName]"
                      }
                    })
                  ],
                  1
                )
              }),
              _vm.product.available > 0
                ? _c(
                    "div",
                    {
                      style: {
                        "min-height": _vm.product.warehousePromo ? "70px" : ""
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _vm.product.priceDisplay
                            ? _c("ProductPrice", {
                                attrs: { product: _vm.product }
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "iva align-self-end" }, [
                            _vm._v(_vm._s(_vm.product.ivaCategory.descr))
                          ])
                        ],
                        1
                      ),
                      !_vm.showQntyInput
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { left: "", "max-width": "250" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "w-100" },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            !_vm.showQntyInput
                                              ? _c("ProductQty", {
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    selectedOptions:
                                                      _vm.selectedOptions,
                                                    product: _vm.product,
                                                    showAddToCartLabel: true,
                                                    isProductDetail: true,
                                                    label: _vm.$t(
                                                      "products.addToCart"
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1357797969
                              )
                            },
                            [
                              _vm.product.productInfos.TIPOLOGIA == "Pezzo" ||
                              _vm.product.productInfos.TIPOLOGIA == "Confezione"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "db-click-tooltip d-block text-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("product.dbClickTooltip")
                                          ) +
                                          " "
                                      ),
                                      _vm.product.logisticPackagingItems
                                        ? [
                                            _c("br"),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "product.logisticPackagingTooltip",
                                                  {
                                                    logisticPackagingItems:
                                                      _vm.product
                                                        .logisticPackagingItems
                                                  }
                                                )
                                              ) + " "
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.product.logisticPalletItems
                                        ? [
                                            _c("br"),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "product.logisticPalletTooltip",
                                                  {
                                                    logisticPalletItems:
                                                      _vm.product
                                                        .logisticPalletItems
                                                  }
                                                )
                                              ) + " "
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "no-db-click-tooltip d-block text-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("product.noDbClickTooltip")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "product-qty" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "qnty-manual-input qty-wrap d-flex align-center rounded-md",
                                  class: { "not-empty": _vm.quantity > 0 },
                                  attrs: { transition: "fab-transition" }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "close px-2",
                                      attrs: { role: "button" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.toggleShowInput(false)
                                        },
                                        mousedown: function($event) {
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("$delete")])],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        "open-on-hover": false,
                                        "open-on-click": true,
                                        "max-width": "500"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "mask",
                                                            rawName: "v-mask",
                                                            value: "###",
                                                            expression: "'###'"
                                                          }
                                                        ],
                                                        ref: "manualInput",
                                                        staticClass:
                                                          "manual-input",
                                                        attrs: {
                                                          "hide-details": "",
                                                          autofocus: "",
                                                          outlined: "",
                                                          dense: "",
                                                          type: "number"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                          },
                                                          keyup: [
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return _vm.toggleShowInput(
                                                                true
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "esc",
                                                                  27,
                                                                  $event.key,
                                                                  [
                                                                    "Esc",
                                                                    "Escape"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return _vm.toggleShowInput(
                                                                false
                                                              )
                                                            }
                                                          ],
                                                          mousedown: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        },
                                                        model: {
                                                          value: _vm.qntyInput,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.qntyInput = $$v
                                                          },
                                                          expression:
                                                            "qntyInput"
                                                        }
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3197411354
                                      )
                                    },
                                    [
                                      this.product.productInfos.QTY_LOCKED !=
                                        "true" ||
                                      (this.product.productInfos.QTY_LOCKED ==
                                        "true" &&
                                        !_vm.modulus(_vm.qntyInput))
                                        ? _c("span", {
                                            staticClass: "qntity-tooltip",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "product.quantity.confirmQntity",
                                                  {
                                                    qntyInput: _vm.qntyInput
                                                  }
                                                )
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      this.product.productInfos.QTY_LOCKED ==
                                        "true" &&
                                      _vm.modulus(_vm.qntyInput) &&
                                      parseInt(_vm.qntyInput) <=
                                        parseInt(
                                          this.product.productInfos.QTY_MULT
                                        )
                                        ? _c("span", {
                                            staticClass: "qntity-tooltip",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "product.quantity.editQntityMin",
                                                  {
                                                    lowerInput: _vm.lowerInput
                                                  }
                                                )
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      this.product.productInfos.QTY_LOCKED ==
                                        "true" &&
                                      _vm.modulus(_vm.qntyInput) &&
                                      parseInt(_vm.qntyInput) >
                                        parseInt(
                                          this.product.productInfos.QTY_MULT
                                        )
                                        ? _c("span", {
                                            staticClass: "qntity-tooltip",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "product.quantity.editQntityMinMax",
                                                  {
                                                    lowerInput: _vm.lowerInput,
                                                    higherInput: _vm.higherInput
                                                  }
                                                )
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "check px-2",
                                      attrs: { role: "button" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.toggleShowInput(true)
                                        },
                                        mousedown: function($event) {
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("$success")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "add-to-cart-btn",
                                  attrs: {
                                    depressed: "",
                                    height: "50",
                                    "min-width": "50",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      _vm.toggleShowInput(true)
                                      _vm.addToCart(_vm.quantity, {
                                        infos: { ..._vm.selectedOptions }
                                      })
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("$cart")]),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(_vm._s(_vm.$t("products.addToCart")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _vm.product.available == 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "actions justify-center text-center text-caption error--text font-weight-bold"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("product.notAvailable")) + " ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "w-100",
                  attrs: {
                    depressed: "",
                    height: "50",
                    "min-width": "50",
                    "max-width": "500px",
                    color: "secondary",
                    outlined: "",
                    href: `/ebsn/api/sidal-datasheet/pdf?product_id=${this.product.productId}`
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.downloadDocument(_vm.product.productId)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("product.downloadTheProductSheet")) +
                      " "
                  )
                ]
              ),
              _vm.product &&
              _vm.product.metaData.product_description &&
              _vm.product.metaData.product_description.informazioni_generali
                ? _c(
                    "div",
                    { staticClass: "product-detail-information mt-2" },
                    [
                      _c(
                        "p",
                        { staticClass: "font-weight-bold secondary--text" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("product.informationOnProduct")) +
                              " "
                          )
                        ]
                      ),
                      _c("span", {
                        staticClass: "product-detail-information-table",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.product.metaData.product_description
                              .informazioni_generali
                          )
                        }
                      })
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }