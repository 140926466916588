var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "product-class" },
    [
      _c("v-chip", { attrs: { color: "secondary", outlined: "" } }, [
        _vm._v(" " + _vm._s(_vm.props.pClass.name))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }