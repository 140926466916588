var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rendered.length
    ? _c(
        "div",
        _vm._l(_vm.rendered, function(item) {
          return _c(
            "div",
            { key: item.key },
            _vm._l(_vm.getWarnings(item["items-map"]), function(warning, idx) {
              return _c("div", { key: idx }, [
                _c("div", {
                  staticClass: "font-weight-bold",
                  domProps: { innerHTML: _vm._s(`${warning.name}:`) }
                }),
                _c("div", { domProps: { innerHTML: _vm._s(warning.value) } })
              ])
            }),
            0
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }