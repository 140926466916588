<template>
  <v-row v-if="product" class="product-detail">
    <v-col cols="12" sm="6" class="py-0 py-sm-3">
      <v-card flat v-if="selectedImage">
        <ProductImages
          :images="product.media"
          :name="product.name"
          :key="product.productId"
        />
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      class="py-0 py-sm-3 default--text"
      style="position:relative"
      ><div class="d-flex justify-space-between align-center">
        <h1 class="secondary--text">
          {{ product.name }}
        </h1>
        <span>
          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                large
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
                aria-label="Aggiungi ad una lista"
              >
                <transition name="pulse">
                  <v-icon color="primary" :key="highlight">
                    {{ highlight ? "$heartfull" : "$heart" }}
                  </v-icon>
                </transition>
              </v-btn>
            </template>
            <span>{{ $t("product.addToList") }}</span>
          </v-tooltip>
          <v-tooltip top v-if="recipeList && recipeList.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                link
                :to="recipeList[0].recipe_url"
                aria-label="Vai alle ricette con questo prodotto"
              >
                <v-icon color="grey darken-1">$recipe</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("product.goToRecipe") }}</span>
          </v-tooltip>
        </span>
      </div>
      <h4 class="description">
        {{ product.description }} {{ product.shortDescr }}
        <span
          v-if="
            product.productInfos &&
              product.productInfos.TIPOLOGIA == 'Pezzo' &&
              product.priceUmDisplay
          "
        >
          al {{ product.weightUnitDisplay }}
          {{ $n(product.priceUmDisplay, "currency") }}
        </span>
      </h4>
      <span
        class="text-caption"
        v-if="
          product.productInfos.TIPOLOGIA == 'Pezzo' &&
            product.productInfos.PACKAGE_DESCR
        "
      >
        {{ product.productInfos.PACKAGE_DESCR }}
      </span>
      <div class="code">
        <v-chip>
          Cod.Art. <strong> {{ product.codInt }}</strong>
        </v-chip>
      </div>
      <div
        class="mb-4 mb-sm-0 mb-md-3 characteristics"
        v-if="productClasses && productClasses.length > 0"
      >
        <div class="mb-3 font-weight-bold">
          {{ $t("product.characteristics") }}
        </div>
        <div class="d-flex flex-wrap product-class-wraper">
          <ProductClass
            :pClass="pClass"
            :width="34"
            :height="34"
            v-for="pClass in productClasses"
            :key="pClass.productClassId"
          />
        </div>
      </div>
      <div class="promo-container my-2">
        <div class="product-badge" v-if="product.productInfos.BADGE">
          {{ product.productInfos.BADGE }}
        </div>
        <ProductPromo
          v-if="product.warehousePromo"
          :warehousePromo="product.warehousePromo"
        />
        <span
          v-else-if="product.newProduct == true"
          class="promo-box new-product"
        >
          NOVITA'
        </span>
      </div>
      <div
        class="option-wrapper mt-3"
        v-for="selectOption in selectOptionsArray"
        :key="selectOption.name"
        @click.stop.prevent
        @mousedown.stop
      >
        <v-select
          outlined
          dense
          v-model="selectedOptions[selectOption.parameterName]"
          :items="selectOption.options"
          :label="selectOption.name"
          item-text="key"
          item-value="value"
          @change="optionChanged"
        />
      </div>

      <div
        v-if="product.available > 0"
        :style="{ 'min-height': product.warehousePromo ? '70px' : '' }"
      >
        <div class="d-flex">
          <ProductPrice
            v-if="product.priceDisplay"
            :product="product"
          ></ProductPrice>
          <div class="iva align-self-end">{{ product.ivaCategory.descr }}</div>
        </div>

        <v-tooltip left v-if="!showQntyInput" max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <div class="w-100" v-bind="attrs" v-on="on">
              <ProductQty
                v-if="!showQntyInput"
                class="mt-3"
                :selectedOptions="selectedOptions"
                :product="product"
                :showAddToCartLabel="true"
                :isProductDetail="true"
                :label="$t('products.addToCart')"
              />
            </div>
          </template>
          <span
            class="db-click-tooltip d-block text-center"
            v-if="
              product.productInfos.TIPOLOGIA == 'Pezzo' ||
                product.productInfos.TIPOLOGIA == 'Confezione'
            "
          >
            {{ $t("product.dbClickTooltip") }}
            <template v-if="product.logisticPackagingItems">
              <br />{{
                $t("product.logisticPackagingTooltip", {
                  logisticPackagingItems: product.logisticPackagingItems
                })
              }}
            </template>
            <template v-if="product.logisticPalletItems">
              <br />{{
                $t("product.logisticPalletTooltip", {
                  logisticPalletItems: product.logisticPalletItems
                })
              }}
            </template>
          </span>
          <span v-else class="no-db-click-tooltip d-block text-center">
            {{ $t("product.noDbClickTooltip") }}
          </span>
        </v-tooltip>
        <div class="product-qty" v-else>
          <div
            class="qnty-manual-input qty-wrap d-flex align-center rounded-md"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <a
              role="button"
              class="close px-2"
              @click.stop.prevent="toggleShowInput(false)"
              @mousedown.stop
            >
              <v-icon>$delete</v-icon>
            </a>
            <v-tooltip
              bottom
              :open-on-hover="false"
              :open-on-click="true"
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="qntyInput"
                  ref="manualInput"
                  class="manual-input"
                  hide-details
                  autofocus
                  outlined
                  dense
                  v-mask="'###'"
                  type="number"
                  @click.stop.prevent=""
                  @keyup.enter="toggleShowInput(true)"
                  @keyup.esc="toggleShowInput(false)"
                  @mousedown.stop
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span
                class="qntity-tooltip"
                v-if="
                  this.product.productInfos.QTY_LOCKED != 'true' ||
                    (this.product.productInfos.QTY_LOCKED == 'true' &&
                      !modulus(qntyInput))
                "
                v-html="
                  $t('product.quantity.confirmQntity', {
                    qntyInput: qntyInput
                  })
                "
              >
              </span>
              <span
                class="qntity-tooltip"
                v-if="
                  this.product.productInfos.QTY_LOCKED == 'true' &&
                    modulus(qntyInput) &&
                    parseInt(qntyInput) <=
                      parseInt(this.product.productInfos.QTY_MULT)
                "
                v-html="
                  $t('product.quantity.editQntityMin', {
                    lowerInput: lowerInput
                  })
                "
              >
              </span>

              <span
                class="qntity-tooltip"
                v-if="
                  this.product.productInfos.QTY_LOCKED == 'true' &&
                    modulus(qntyInput) &&
                    parseInt(qntyInput) >
                      parseInt(this.product.productInfos.QTY_MULT)
                "
                v-html="
                  $t('product.quantity.editQntityMinMax', {
                    lowerInput: lowerInput,
                    higherInput: higherInput
                  })
                "
              >
              </span>
            </v-tooltip>
            <a
              role="button"
              class="check px-2"
              @click.stop.prevent="toggleShowInput(true)"
              @mousedown.stop
            >
              <v-icon>$success</v-icon>
            </a>
          </div>

          <v-btn
            depressed
            class="add-to-cart-btn"
            height="50"
            min-width="50"
            color="primary"
            @click.stop.prevent="
              toggleShowInput(true);
              addToCart(quantity, {
                infos: { ...selectedOptions }
              });
            "
          >
            <v-icon>$cart</v-icon>
            <span class="ml-2">{{ $t("products.addToCart") }}</span>
          </v-btn>
        </div>
      </div>
      <div
        v-else-if="product.available == 0"
        class="actions justify-center text-center text-caption error--text font-weight-bold"
      >
        {{ $t("product.notAvailable") }}
      </div>
      <v-btn
        depressed
        class="w-100"
        height="50"
        min-width="50"
        max-width="500px"
        color="secondary"
        outlined
        :href="
          `/ebsn/api/sidal-datasheet/pdf?product_id=${this.product.productId}`
        "
        @click.stop.prevent="downloadDocument(product.productId)"
      >
        {{ $t("product.downloadTheProductSheet") }}
      </v-btn>
      <div
        class="product-detail-information mt-2"
        v-if="
          product &&
            product.metaData.product_description &&
            product.metaData.product_description.informazioni_generali
        "
      >
        <p class="font-weight-bold secondary--text">
          {{ $t("product.informationOnProduct") }}
        </p>
        <span
          class="product-detail-information-table"
          v-html="product.metaData.product_description.informazioni_generali"
        ></span>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.product-detail {
  span.point::before {
    content: " \25CF";
    font-size: 20px;
    line-height: 12px;
    color: #ed7c20;
  }
  h1 {
    font-size: 32px;
    line-height: 33px;
  }
  h2 {
    font-size: 23px;
    font-weight: 600;
  }
  .product-price {
    width: auto;
    .product_promo {
      position: relative;
      & > .card {
        bottom: 44px;
      }
      .bubble {
        top: initial;
        bottom: 0;
        left: 150px;
      }
    }
  }
  .vendorIcon {
    position: absolute;
    right: 6px;
    top: -18px;
    z-index: 6;
  }

  .price-um {
    font-size: 12px !important;
    margin-top: 5px !important;
  }
  .add-to-list-text.text-body-2 {
    font-size: 16px !important;
  }
  .description {
    font-weight: normal;
    font-size: 16px;
  }
  .code {
    .v-chip {
      font-size: 12px;
      height: 28px;
    }
  }
  .characteristics {
    margin-top: 45px;
    span {
      font-size: 16px;
    }
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .product-class-wraper {
    column-gap: 8px;
    row-gap: 5px;
  }
  &-information {
    &-table tr {
      td:nth-child(1) {
        font-weight: bold;
      }
      td:nth-child(2) {
        padding-left: 10px;
      }
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductQty from "./ProductQty.vue";
import clickHandler from "~/mixins/clickHandler";

// import ProductRating from "@/components/product/ProductRating.vue";
// import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";

import productMixin from "~/mixins/product";
import { mapState, mapGetters } from "vuex";

import cloneDeep from "lodash/cloneDeep";
import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";
import downloadDocument from "~/mixins/downloadDocument";
import WpSidalCustomService from "@/service/wpSidalCustomService";

export default {
  name: "ProductDetail",
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  components: {
    ProductPrice,
    ProductPromo,
    ProductClass,
    ProductImages,
    // ProductGiftCertificate,
    ProductQty
    // ProductRating
    // ProductCustomerVotes
  },
  mixins: [productMixin, clickHandler, downloadDocument],
  props: ["selectedImage", "product"],
  data() {
    return {
      imageDetailDialog: false,
      selectedOptions: {},
      selectOptionsArray: [],
      recipeList: []
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    }
  },
  methods: {
    downloadDocument(productId) {
      var url = window.event.currentTarget.href;
      this.handleReceiptDownload(url, productId);
    },
    goToProductCustomerVote() {
      // this.$vuetify.goTo(this.$refs.disclaimerform);
      this.$emit("goToCustomerVote", true);
    },
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    setOptionsArray() {
      if (this.product.selectOptions && this.product.selectOptions.length) {
        this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      } else {
        this.selectOptionsArray = [];
      }
    },
    async getRecipeList() {
      this.recipeList = await WpSidalCustomService.getRecipeByProductId(
        this.product.productId
      );
    }
  },
  created() {
    this.setOptionsArray();
    this.getRecipeList();
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        this.setOptionsArray();
      }
    }
  }
};
</script>
