import { render, staticRenderFns } from "./ProductDescription.vue?vue&type=template&id=00d00f46"
import script from "./ProductDescription.vue?vue&type=script&lang=js"
export * from "./ProductDescription.vue?vue&type=script&lang=js"
import style0 from "./ProductDescription.vue?vue&type=style&index=0&id=00d00f46&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/rifatosmani/workspace/Abbondanza/ebsn-front-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00d00f46')) {
      api.createRecord('00d00f46', component.options)
    } else {
      api.reload('00d00f46', component.options)
    }
    module.hot.accept("./ProductDescription.vue?vue&type=template&id=00d00f46", function () {
      api.rerender('00d00f46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/product/ProductDescription.vue"
export default component.exports