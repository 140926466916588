var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-images" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center pl-3",
              attrs: {
                cols:
                  _vm.$vuetify.breakpoint.xs || _vm.images.length < 2
                    ? "12"
                    : "9",
                order: "2"
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-block-wrapper" },
                [
                  _vm.$vuetify.breakpoint.xs
                    ? _c("img", {
                        staticClass: "image-mobile",
                        attrs: {
                          src: _vm.selectedImage.medium,
                          alt: "Immagine prodotto " + _vm.name,
                          onerror: "this.onerror=null;this.src='/no-icon.png'"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.dialog = true
                          }
                        }
                      })
                    : _c("zoom-on-hover", {
                        attrs: {
                          scale: 1.5,
                          "img-normal": _vm.selectedImage.medium,
                          "img-zoom": _vm.selectedImage.large
                        }
                      })
                ],
                1
              )
            ]
          ),
          _vm.images.length > 1
            ? _c(
                "v-col",
                {
                  staticClass: "d-sm-flex d-none justify-center thumb-wrapper",
                  attrs: { cols: "3", order: "1" }
                },
                [
                  _c("div", {
                    staticClass: "swiper-button-next",
                    attrs: { slot: "button-next" },
                    slot: "button-next"
                  }),
                  _c(
                    "swiper",
                    {
                      ref: "swiperThumb",
                      staticClass: "swiper",
                      attrs: { options: _vm.swiperOptionThumbs }
                    },
                    _vm._l(_vm.images, function(media) {
                      return _c("swiper-slide", { key: media.medium }, [
                        _c("img", {
                          key: "img_" + media.medium,
                          class: { selected: _vm.selectedImage == media },
                          attrs: {
                            src: media.small,
                            alt: "Anteprima immagine prodotto " + _vm.name,
                            onerror: "this.onerror=null;this.src='/no-icon.png'"
                          },
                          on: {
                            click: function($event) {
                              _vm.selectedImage = media
                            }
                          }
                        })
                      ])
                    }),
                    1
                  ),
                  _c("div", {
                    staticClass: "swiper-button-prev",
                    attrs: { slot: "button-prev" },
                    slot: "button-prev"
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "zoom-dialog",
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "div",
            { staticClass: "zoom-dialog-content mt-4" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        large: "",
                        width: "250",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.close")))]
                  )
                ],
                1
              ),
              _c(
                "pinch-zoom",
                { staticClass: "mt-6", attrs: { height: "100%" } },
                [_c("img", { attrs: { src: _vm.selectedImage.large } })]
              )
            ],
            1
          ),
          _vm.images.length > 1
            ? _c(
                "div",
                { staticClass: "d-flex flex-row zoom-swiper-wrapper mt-6" },
                [
                  _c(
                    "swiper",
                    {
                      staticClass: "swiper",
                      attrs: { options: _vm.swiperOptionThumbs }
                    },
                    _vm._l(_vm.images, function(media) {
                      return _c("swiper-slide", { key: media.medium }, [
                        _c("img", {
                          key: "img_" + media.medium,
                          class: { selected: _vm.selectedImage == media },
                          attrs: {
                            src: media.small,
                            alt: "Anteprima immagine prodotto " + _vm.name,
                            onerror: "this.onerror=null;this.src='/no-icon.png'"
                          },
                          on: {
                            click: function($event) {
                              _vm.selectedImage = media
                            }
                          }
                        })
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }