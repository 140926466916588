var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rendered
    ? _c("div", [
        _c(
          "ul",
          _vm._l(_vm.rendered.options, function(option, idx) {
            return _c("li", {
              key: idx,
              domProps: { innerHTML: _vm._s(option.text) }
            })
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }