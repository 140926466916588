var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabs.length > 0
    ? _c("div", { staticClass: "product-description pb-10" }, [
        _c(
          "div",
          [
            _c(
              "v-tabs",
              {
                attrs: {
                  "slider-size": "0",
                  "show-arrows": _vm.$vuetify.breakpoint.xs
                },
                model: {
                  value: _vm.selectedTab,
                  callback: function($$v) {
                    _vm.selectedTab = $$v
                  },
                  expression: "selectedTab"
                }
              },
              _vm._l(_vm.tabs, function(tab, idx) {
                return _c("v-tab", { key: idx }, [
                  _c("h2", { staticClass: "text-body-2 text-uppercase" }, [
                    _vm._v(" " + _vm._s(tab.title) + " ")
                  ])
                ])
              }),
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.selectedTab,
                  callback: function($$v) {
                    _vm.selectedTab = $$v
                  },
                  expression: "selectedTab"
                }
              },
              _vm._l(_vm.tabs, function(tab) {
                return _c(
                  "v-tab-item",
                  { key: tab.title, class: tab.cssClass },
                  _vm._l(tab.items, function(item) {
                    return _c(
                      "div",
                      { key: item.title, class: item.cssClass },
                      [
                        item.title
                          ? _c(
                              "h3",
                              {
                                staticClass:
                                  "text-body-2 text-uppercase font-weight-bold mb-1 mt-3"
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          : _vm._e(),
                        _vm._l(item.paths, function(path) {
                          return _c(
                            "div",
                            {
                              key: path.key,
                              staticClass: "text-body-2 content default--text"
                            },
                            [
                              _c(path.template || "DefaultTpl", {
                                tag: "component",
                                staticClass: "component pb-4",
                                attrs: {
                                  content: path.content,
                                  append: path.append
                                }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              }),
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }